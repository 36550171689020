import React, { useRef, useEffect, useCallback } from 'react';
import './main.css';
import getImage from '../../assets/getImage';
import getIcon from '../../assets/getIcon';
import { MainSection } from './sections/main/mainSection';
import { GetStartedSection } from './sections/getStarted/getStartedSection';
import { ApplicationSection } from './sections/application/applicationSection';
import { FAQSection } from './sections/FAQ/faqSection';
import { FeedBackSection } from './sections/feedback/feedBackSection';
import { AsidePanel } from './UI/asidePanel/asidePanel';

const indexes = [
  { name: 'Главная', id: 1, sectionRef: 'main__section' },
  { name: 'Начало работы', id: 2, sectionRef: 'get__started__section' },
  { name: 'Приложение', id: 3, sectionRef: 'application__section' },
  { name: 'Вопрос-Ответы', id: 4, sectionRef: 'faq__section' },
  { name: 'Отзывы', id: 5, sectionRef: 'feedback__section' },
];

const questions = [
  {
    id: 1,
    text: 'Сколько по времени длится мероприятие?',
    answer: `Среднее время мероприятия составляет 3-4 часа, с учётом дороги туда и обратно.`
  },
  {
    id: 2,
    text: 'Что будем делать в приюте?',
    answer: ` Мы делаем то, что попросит приют: помогаем в выгуле собак, фасуем корм или чистим овощи для лосей, когда мы
            едем к диким зверям. Можем выполнять и не слишком чистую работу, такую как уборка вальеров.`
  },
  {
    id: 3,
    text: 'Как одеваться?',
    answer: ` Так как мы иногда помогаем на свежем воздухе, одевайтесь по погоде в то, что не жалко испачкать или легко
            отстирывается. Возьмите с собой сменную обувь, лучше всего резиновые сапоги.`
  },
  {
    id: 4,
    text: 'Что брать с собой?',
    answer: ` Воду и перекус по желанию, антисептик или влажные салфетки, сменные носки, на случай, если промочите ноги.
            Также можете захватить угощения или игрушки для животных, жители приютов будут очень рады.`
  },
  {
    id: 5,
    text: 'С какого возраста можно стать волонтёром Империи?',
    answer: `С любого. Мы подберём для вас подходящий приют и расскажем как сделать помощь весёлой и безопасной.`
  }
]

const feedbacks = [
  {
    id: 1,
    firstname: 'Алена',
    surname: 'Струева',
    from: 'VK',
    networkId: 'Deimosgirl',
    avatar: `${getImage('woman_1.png')}`,
    text: `Была вчера в приюте «Друг».
 Благодаря Империи Добра без проблем нашла дорогу и прекрасно провела время с собачкой Айзой.
 Помогать другим - животным или людям, это классно, тем более, если видишь, что есть 🥴единомышленники.
 Поэтому присоединяйтесь, делитесь теплом и вниманием и получайте его взамен😍👍`
  },

  {
    id: 2,
    firstname: 'Юлия',
    surname: 'Смирнова',
    from: 'vk',
    networkId: 'Anfisa_ahmetshina',
    avatar: `${getImage('woman_2.png')}`,
    text: `Огромная благодарность организаторам за эту поездку! Впечатлений море, сил - ноль)) Все прошло супер, нас встретили (никого не потеряли 😻, доставили. И без приключений вернули обратно) Все собаки очень милые и добрые)
Спасибо вам за то, что делаете помощь доступной  каждому`
  },

  {
    id: 3,
    firstname: 'Оксана',
    surname: 'Боброва',
    from: 'vk',
    networkId: 'Zhuva22',
    avatar: `${getImage('woman_3.png')}`,
    text: `Спасибо большое за отличную поездку, впервые поехала в приют, все благодаря организации Империи добра) Это было непросто, но приятная усталость и эмоции запомнятся надолго. Желаю
дальнейшего развития, нет ничего лучше, чем помогать животным)❤️`
  },

  {
    id: 4,
    firstname: 'Лида',
    surname: 'Аношкина',
    from: 'vk',
    networkId: 'ID529121380',
    avatar: `${getImage('woman_4.png')}`,
    text: `Совсем недавно ездила с Империей добра в приют Келтсские ангелы к милым пушистым созданиям)
Было приятно не только погулять под солнцем вместе с собачками, но и приятно провести время с другими ребятами, которые были на выезде. Обязательно присоединюсь к ребятам на следующие выезды`
  },

  {
    id: 5,
    firstname: 'Валерия',
    surname: 'Гартунг',
    from: 'vk',
    networkId: 'Korolevskaya_viverna',
    avatar: `${getImage('woman_5.png')}`,
    text: `Ездила в приют Велес с молодым человеком, который ни разу не бывал в таких местах и не знал вообще, что это такое, но даже ему очень понравилось!😍👍
Я тоже была в восторге, так как до этого бывала лишь в приютах для собак и кошек.
Спасибо организаторам за эту поездку, обязательно съездим еще🥴`
  }
]

function scrollToMiddle() {
  const middlePositionOnScreen = window.innerHeight

  return window.scrollTo({
    top: middlePositionOnScreen,
    behavior: 'smooth'
  })
}

export const Main = () => {
  const [isMobilePanel, setMobilePanel] = React.useState(false);
  const [currIndex, setCurrIndex] = React.useState(indexes[0]);
  const [isScrolling, setIsScrolling] = React.useState(false);
  const circleRef = useRef(null);
  const buttonRefs = useRef([]);
  const sectionRefs = useRef([]);

  const mobilePanelHandler = () => {
    setMobilePanel(prev => !prev);
  };

  const smoothScroll = (element) => {
    setIsScrolling(true); // Устанавливаем флаг, что мы в процессе скролла
    const elementToScroll = document.querySelector(`${element}`);
    elementToScroll.scrollIntoView({ behavior: 'smooth', block: 'center' });

    // После завершения скролла
    setTimeout(() => {
      setIsScrolling(false); // Возвращаем флаг в исходное состояние
    }, 600); // Время, достаточное для завершения скролла
  };

  const handleScroll = useCallback(() => {
    if (isScrolling) {
      return; // Если в процессе скролла, выходим
    }
    sectionRefs.current.forEach((section, index) => {
      if (
        section &&
        section.getBoundingClientRect().top >= 0 &&
        section.getBoundingClientRect().top < window.innerHeight - 300
      ) {
        setCurrIndex(indexes[index]);
      }
    });
  }, [isScrolling]);

  const scrollToSection = (section__id) => {
    smoothScroll(`#${section__id}`);
  };

  useEffect(() => {
    document.body.classList.add('no__x__scrolling');

    return () => {
      document.body.classList.remove('no__x__scrolling');
    };
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll, isScrolling]);

  useEffect(() => {
    const activeButton = buttonRefs.current[currIndex.id - 1];
    if (circleRef.current && activeButton) {
      circleRef.current.style.width = `${activeButton.offsetWidth}px`;
      circleRef.current.style.transform = `translateX(${activeButton.offsetLeft}px)`;
    }
  }, [currIndex]);

  return (
    <div className="main__page">
      {isMobilePanel && <AsidePanel
        indexes={indexes}
        currIndex={currIndex}
        asidePanelHandler={mobilePanelHandler}
        setCurrIndex={setCurrIndex}
        scrollToSection={scrollToSection} />}
      <header className='main__page__header'>
        <a href="https://dobrayaimperia.ru/">
          <picture className='header__logo'>
            <source
              media="(min-width: 1026px)"
              srcSet={getImage('header__dekstop__logo.png')}
            />
            <source
              media="(min-width: 450px)"
              srcSet={getImage('header__adaptive__logo__2x.png')}
            />
            <img
              draggable={false}
              className='adaptive'
              src={getImage('header__adaptive__logo__2x.png')}
              alt="Logo"
            />
          </picture>
        </a>

        <nav className='adaptive main__page__navigation'>
          <div onClick={mobilePanelHandler} className='styled__border__button'>
            <img draggable={false} src={getIcon('BurgerMenu.svg')} alt='Menu' />
          </div>

          <a href='https://vk.com/dobrayaimperia' className='styled__border__button'>
            <img draggable={false} src={getIcon('Vk.svg')} alt='VK' />
          </a>

          <a href='https://t.me/dobrayaimperia' className='styled__border__button'>
            <img draggable={false} src={getIcon('Telegram.svg')} alt='Telegram' />
          </a>
        </nav>

        <nav className='dekstop main__page__navigation'>
          {indexes.map((index) => (
            <button
              ref={el => buttonRefs.current[index.id - 1] = el}
              className={index.id === currIndex.id ? 'active' : 'not-active'}
              key={index.id}
              type='button'
              onClick={() => {
                setCurrIndex(index);
                scrollToSection(index.sectionRef);
              }}>
              <span className='nav__button__text'>{index.name}</span>
            </button>
          ))}
          <div className='styled__button blue circle' ref={circleRef} />
        </nav>
      </header>

      <div ref={el => sectionRefs.current[0] = el}><MainSection scrollToMiddle={scrollToMiddle} /></div>
      <div ref={el => sectionRefs.current[1] = el}><GetStartedSection /></div>
      <div ref={el => sectionRefs.current[2] = el}><ApplicationSection /></div>
      <div ref={el => sectionRefs.current[3] = el}><FAQSection questions={questions} /></div>
      <div ref={el => sectionRefs.current[4] = el}><FeedBackSection feedbacks={feedbacks} /></div>

      <a href='https://dobrayaimperia.ru/reg' className='styled__button aqua fixed__landing__button dekstop'>
        Стать волонтёром
      </a>

      <a href='https://dobrayaimperia.ru/reg' className='styled__button blue fixed__landing__button adaptive'>
        Стать волонтёром
      </a>

      <footer className='main__page__footer'>
        <img draggable={false} className='footer__image__to__left' src={getImage('blind_girl_with_dog.png')} alt='Left' />
        <img draggable={false} className='footer__image__to__right' src={getImage('girl_trains_a_dog.png')} alt='Right' />
        <div className='footer__partners__container'>
          <span className='footer__partner__personal__identity'>ИНН: 7804681810 ОГРН: 1217800056407</span>
          <span className='footer__partner__email'>partners@dobrayaimperia.ru</span>
        </div>
        <a href='https://dobrayaimperia.ru/privacy-policy'>Политика конфиденциальности</a>
        <div className='partners__logos'>
          <img draggable={false} src={getIcon('Visa.svg')} alt='Visa' />
          <img draggable={false} src={getIcon('Mastercard.svg')} alt='Mastercard' />
          <img draggable={false} src={getIcon('Google-pay.svg')} alt='Google pay' />
          <img draggable={false} src={getIcon('Mir.svg')} alt='Mir' />
        </div>

        <div className='copiryting__container'>
          <span>By Tech Wizards</span>
          <span>2024</span>
        </div>
      </footer>
    </div>
  );
};