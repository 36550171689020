// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html, body {
  height: 100%;
  margin: 0;
  font-family: 'Montserrat';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input,
textarea,
button,
select,
label,
span,
a {
    -webkit-tap-highlight-color: transparent;
}

#webpack-dev-server-client-overlay {
  display: none !important;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,SAAS;EACT,yBAAyB;EACzB,mCAAmC;EACnC,kCAAkC;EAClC,uBAAuB;AACzB;;AAEA;EACE;aACW;AACb;;AAEA;;;;;;;IAOI,wCAAwC;AAC5C;;AAEA;EACE,wBAAwB;AAC1B","sourcesContent":["html, body {\r\n  height: 100%;\r\n  margin: 0;\r\n  font-family: 'Montserrat';\r\n  -webkit-font-smoothing: antialiased;\r\n  -moz-osx-font-smoothing: grayscale;\r\n  scroll-behavior: smooth;\r\n}\r\n\r\ncode {\r\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\r\n    monospace;\r\n}\r\n\r\ninput,\r\ntextarea,\r\nbutton,\r\nselect,\r\nlabel,\r\nspan,\r\na {\r\n    -webkit-tap-highlight-color: transparent;\r\n}\r\n\r\n#webpack-dev-server-client-overlay {\r\n  display: none !important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
