// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input {
    width: 488px;
    height: 55px;
    flex-shrink: 0;

    padding: 17px 28px;
    box-sizing: border-box;

    border: none;
    border-radius: 16px;
    background: #F9F9FC;
    box-shadow: 0px 1px 4px 0px #ECECEF;

    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    font-family: 'Montserrat';

    outline: none;
}

.input--pass {
    padding-top: 0;
    padding-bottom: 0;

    display: flex;
    align-items: center;
}

.input-pass {
    width: 100%;
    height: 100%;

    border: none;
    border-radius: 16px;
    background: transparent;

    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    font-family: 'Montserrat';

    outline: none;
}

@media (max-width: 540px) {
    .input {
        width: 368px;
        height: 55px;
    }
}

@media (max-width: 425px) {
    .input {
        width: 320px;
        height: 45px;

        padding: 15px 19px;

        font-size: 14px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/common/input/input.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,YAAY;IACZ,cAAc;;IAEd,kBAAkB;IAClB,sBAAsB;;IAEtB,YAAY;IACZ,mBAAmB;IACnB,mBAAmB;IACnB,mCAAmC;;IAEnC,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,yBAAyB;;IAEzB,aAAa;AACjB;;AAEA;IACI,cAAc;IACd,iBAAiB;;IAEjB,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;;IAEZ,YAAY;IACZ,mBAAmB;IACnB,uBAAuB;;IAEvB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,yBAAyB;;IAEzB,aAAa;AACjB;;AAEA;IACI;QACI,YAAY;QACZ,YAAY;IAChB;AACJ;;AAEA;IACI;QACI,YAAY;QACZ,YAAY;;QAEZ,kBAAkB;;QAElB,eAAe;IACnB;AACJ","sourcesContent":[".input {\r\n    width: 488px;\r\n    height: 55px;\r\n    flex-shrink: 0;\r\n\r\n    padding: 17px 28px;\r\n    box-sizing: border-box;\r\n\r\n    border: none;\r\n    border-radius: 16px;\r\n    background: #F9F9FC;\r\n    box-shadow: 0px 1px 4px 0px #ECECEF;\r\n\r\n    font-size: 16px;\r\n    font-style: normal;\r\n    font-weight: 600;\r\n    line-height: normal;\r\n    font-family: 'Montserrat';\r\n\r\n    outline: none;\r\n}\r\n\r\n.input--pass {\r\n    padding-top: 0;\r\n    padding-bottom: 0;\r\n\r\n    display: flex;\r\n    align-items: center;\r\n}\r\n\r\n.input-pass {\r\n    width: 100%;\r\n    height: 100%;\r\n\r\n    border: none;\r\n    border-radius: 16px;\r\n    background: transparent;\r\n\r\n    font-size: 16px;\r\n    font-style: normal;\r\n    font-weight: 600;\r\n    line-height: normal;\r\n    font-family: 'Montserrat';\r\n\r\n    outline: none;\r\n}\r\n\r\n@media (max-width: 540px) {\r\n    .input {\r\n        width: 368px;\r\n        height: 55px;\r\n    }\r\n}\r\n\r\n@media (max-width: 425px) {\r\n    .input {\r\n        width: 320px;\r\n        height: 45px;\r\n\r\n        padding: 15px 19px;\r\n\r\n        font-size: 14px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
