// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button {
    padding: 18px 0;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 16px;

    color: #fff;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;

    background: var(--primary-color);
    box-sizing: border-box;
}`, "",{"version":3,"sources":["webpack://./src/components/common/button/button.css"],"names":[],"mappings":"AAAA;IACI,eAAe;;IAEf,aAAa;IACb,uBAAuB;IACvB,mBAAmB;;IAEnB,mBAAmB;;IAEnB,WAAW;IACX,eAAe;IACf,kBAAkB;IAClB,gBAAgB;;IAEhB,gCAAgC;IAChC,sBAAsB;AAC1B","sourcesContent":[".button {\r\n    padding: 18px 0;\r\n\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n\r\n    border-radius: 16px;\r\n\r\n    color: #fff;\r\n    font-size: 18px;\r\n    font-style: normal;\r\n    font-weight: 500;\r\n\r\n    background: var(--primary-color);\r\n    box-sizing: border-box;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
