// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.newpass__content-title {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 168.9%;
}

.newpass__content-title--mail {
    font-weight: 700;
    text-decoration-line: underline;
}

@media (max-width: 1024px) {
.newpass__content-title {
    text-align: center;
}
}`, "",{"version":3,"sources":["webpack://./src/screens/newpass/newpass.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,+BAA+B;AACnC;;AAEA;AACA;IACI,kBAAkB;AACtB;AACA","sourcesContent":[".newpass__content-title {\r\n    font-size: 18px;\r\n    font-style: normal;\r\n    font-weight: 500;\r\n    line-height: 168.9%;\r\n}\r\n\r\n.newpass__content-title--mail {\r\n    font-weight: 700;\r\n    text-decoration-line: underline;\r\n}\r\n\r\n@media (max-width: 1024px) {\r\n.newpass__content-title {\r\n    text-align: center;\r\n}\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
