import React from 'react';
import { Header } from '../../components/common/header/header';

export const Privacy = () => {
    return (
        <>
        <Header />
        <div style={{paddingLeft: '2em', paddingRight: '2em'}}>
            <h1><b>Пользовательское соглашение</b></h1>
            <p><span style={{fontWeight: '400'}}>Настоящее пользовательское соглашение (далее &mdash; Соглашение) в соответствии с положениями ст. 437 ГК РФ является публичной офертой Автономная некоммерческая организация по развитию волонтерской деятельности &ldquo;Империя добра&rdquo; (ОГРН 1217800056407, ИНН 7804681810, юридический адрес: Российская Федерация, 195271, г. Санкт-Петербург, вн.тер.г. муниципальный округ Пискаревка, Сибирская ул., д. 16, литера А, помещ. 16-Н, офис 2, далее &mdash; Администрация), и адресовано любому дееспособному лицу (далее &mdash; Пользователю) на изложенных ниже условиях. Запуск чат-бота расположенного по адресу https://t.me/imper_family_bot, является безусловным и безоговорочным принятием (акцептом) Пользователем условий Соглашения.</span></p>
            <p></p>
            <p><b>Термины и определения</b></p>
            <p><span style={{fontWeight: '400'}}>Чат-бот (далее &mdash; Бот) &mdash; специальная автоматизированная программа Администрации на платформе telegram,&nbsp; которая в автоматическом режиме может принимать, обрабатывать и отправлять сообщения.</span></p>
            <p><span style={{fontWeight: '400'}}>Аккаунт &mdash; учетная запись Пользователя в чат-боте.</span></p>
            <p><span style={{fontWeight: '400'}}>Контент &mdash; информация, документы и иные объекты, размещаемые Пользователем в Боте.</span></p>
            <p><span style={{fontWeight: '400'}}>Тариф &mdash; объем предоставляемых прав и услуг Пользователю.</span></p>
            <p><span style={{fontWeight: '400'}}>Библиотека - база данных принадлежащая Администрации чат-бот, в которой содержиться Тексовая информация, Аудиоматериалы, Выдеоматериалы, Фотоматериалы, Информация о пользователях.</span></p>
            <p><br /><br /><br /></p>
            <ol>
            <li><b> Предмет</b></li>
            </ol>
            <p><span style={{fontWeight: '400'}}>1.1. Администрация предоставляет неисключительную лицензию на использование Чат-бота и элементов Библиотеки, а также оказывает сопутствующие услуги.</span></p>
            <p><span style={{fontWeight: '400'}}>1.2. Пользователь использует Чат-бот и элементы Библиотеки согласно условиям Соглашения и выбранного Тарифа, а также уплачивает вознаграждение, если иное не предусмотрено Тарифом.</span></p>
            <p></p>
            <ol start="2">
            <li><b> Общие положения</b></li>
            </ol>
            <p><span style={{fontWeight: '400'}}>2.1. Для использования Чат-бот Пользователь проходит регистрацию по адресу https://t.me/imper_family_bot.</span></p>
            <p><span style={{fontWeight: '400'}}>2.3. После регистрации Пользователь получает уникальный Аккаунт.</span></p>
            <p><span style={{fontWeight: '400'}}>2.4. Все действия, совершаемые в Аккаунте, считаются совершенными Пользователем лично. Пользователь несет полную ответственность за любые действия, совершенные с использованием его Аккаунта, а также за любые последствия, которые могло повлечь или повлекло подобное его использование.</span></p>
            <p><span style={{fontWeight: '400'}}>2.5. Пользователь самостоятельно несет ответственность за:</span></p>
            <ol>
            <li><span style={{fontWeight: '400'}}>a) сохранность своего доступа к Аккаунту;</span></li>
            <li><span style={{fontWeight: '400'}}>b) последствия в случае утери утери доступа к Аккаунту и передачи его третьим лицам.</span></li>
            </ol>
            <p><span style={{fontWeight: '400'}}>2.6. Пользователь обязан самостоятельно предпринимать должные меры, обеспечивающие безопасность Аккаунта и предотвращающие несанкционированный доступ к нему третьих лиц.</span></p>
            <p><span style={{fontWeight: '400'}}>2.7. Пользователь не имеет права передавать третьим лицам данные своего Аккаунта. В случае передачи данных Аккаунта Пользователь несет всю ответственность за действия третьих лиц, совершенных через Аккаунт Пользователя.</span></p>
            <p></p>
            <ol start="3">
            <li><b> Условия использования</b></li>
            </ol>
            <p><span style={{fontWeight: '400'}}>3.1. Пользователь вправе использовать Чат-бот согласно Соглашению и действующему законодательству Российской Федерации.</span></p>
            <p><span style={{fontWeight: '400'}}>3.2. Администрация не обязана оказывать консультационную и техническую поддержку Пользователю.</span></p>
            <p><span style={{fontWeight: '400'}}>3.3. Чат-бот интегрирован с различными сервисами, в том числе с сервисами аналитики, платежей, рассылок. Чат-бот обеспечивает только возможность доступа к сервисам. Отношения Пользователя и лица, предоставляющего услуги посредством сервиса (владелец сервиса), по всем вопросам, связанным с использованием сервиса , регулируются документами сервиса (соглашением, правилами использования и т.п.) и решаются между владельцем сервиса и Пользователем самостоятельно.</span></p>
            <p><span style={{fontWeight: '400'}}>3.4. Чат-бот и услуги предоставляются на условиях &laquo;как есть&raquo;.</span></p>
            <p><span style={{fontWeight: '400'}}>3.5. Пользователь принимает на себя все риски, связанные с использованием Чат-бот и услуг.</span></p>
            <p><span style={{fontWeight: '400'}}>3.6. Администрация не дает Пользователю никаких явно выраженных или предполагаемых гарантий в отношении Чат-бот и услуг, в том числе, не гарантирует (включая, но не ограничиваясь): пригодность для конкретных целей, безопасность и защищенность, точность, полноту, производительность, системную интеграцию, бесперебойное функционирование, отсутствие ошибок, исправление неполадок, отсутствие вирусов, законность использования на любых территориях за пределами Российской Федерации.</span></p>
            <p><span style={{fontWeight: '400'}}>3.7. Администрация не несет ответственности за:</span></p>
            <ol>
            <li><span style={{fontWeight: '400'}}>a) невозможность использования Чат-бот по причинам, не зависящим от Администрации;</span></li>
            <li><span style={{fontWeight: '400'}}>b) любые действия и/или бездействия поставщиков услуг, сервисов, сетей, программного обеспечения или оборудования;</span></li>
            <li><span style={{fontWeight: '400'}}>c) искажение, изменение, утрату Контента;</span></li>
            <li><span style={{fontWeight: '400'}}>d) безопасность доступа к Аккаунту Пользователя;</span></li>
            <li><span style={{fontWeight: '400'}}>e) несанкционированное и/или неправомерное использования третьими лицами Аккаунта Пользователя;</span></li>
            <li><span style={{fontWeight: '400'}}>f) ущерб, который может быть нанесен любым устройствам и носителям информации и/или программному обеспечению Пользователя в результате использования Чат-бот и/или услуг;</span></li>
            </ol>
            <p></p>
            <ol start="4">
            <li><b> Интеллектуальная собственность</b></li>
            </ol>
            <p><span style={{fontWeight: '400'}}>4.1. Администрация является правообладателем Чат-бот&nbsp; и ее составных частей (код, дизайн, ноу-хау, Библиотеки).</span></p>
            <p><span style={{fontWeight: '400'}}>4.2. Неисключительная лицензия ограничена &laquo;правом на использование&raquo;, и ни одно из положений Соглашения не означает передачи Пользователю исключительного права на Чат-бот и&nbsp; Библиотеки.</span></p>
            <p><span style={{fontWeight: '400'}}>4.3. Неисключительная лицензия предоставляется на срок действия Соглашения на территорию всего мира.</span></p>
            <p></p>
            <p><b>Чат-бот и Библиотека</b></p>
            <p><span style={{fontWeight: '400'}}>4.4. Пользователь вправе с помощью Чат-бот:</span></p>
            <ol>
            <li><span style={{fontWeight: '400'}}>a) размещать текстовую информацию, аудиоматериалы, видеоматериалы, фотоматериалы, ссылок на материалы, размещенные на других сайтах;</span></li>
            </ol>
            <p><br /><br /></p>
            <p><span style={{fontWeight: '400'}}>4.5.&nbsp; Пользователь вправе использовать элементы Библиотеки (Текстовую информацию, Аудиоматериалы, Видеоматериалы, Фотоматериалы) на территории Чат-бот.</span></p>
            <p><br /><br /><br /></p>
            <p><b>Контент</b></p>
            <p><span style={{fontWeight: '400'}}>4.9. При использовании Бота Пользователь должен соблюдать авторские права.</span></p>
            <p><span style={{fontWeight: '400'}}>4.10. Пользователь гарантирует, что Контент не нарушает права третьих лиц на результаты интеллектуальной деятельности и приравненные к ним средства индивидуализации, права на информацию, составляющую коммерческую тайну, не наносит вреда чести, деловой репутации и достоинству третьих лиц, не нарушает национальное и международное законодательство.</span></p>
            <p><span style={{fontWeight: '400'}}>4.11. Пользователь предоставляет Администрации право на использование Контента в любой форме и любым способом. Право на использование предоставляется на условиях простой (неисключительной) лицензии и без выплаты какого-либо вознаграждения на весь срок действия исключительных прав на территории всего мира. Прекращение действия Соглашения между Сторонами не отменяет настоящее положение.</span></p>
            <p></p>
            <ol start="5">
            <li><b> Экспорт</b></li>
            </ol>
            <p><span style={{fontWeight: '400'}}>5.1. Пользователь не вправе экспортировать и использовать элементы Библиотеки (Текстовую информацию, Аудиоматериалы, Видеоматериалы, Фотоматериалы, Информацию о пользователях) отдельно от Бота.</span></p>
            <p><br /><br /><br /></p>
            <ol start="6">
            <li><b> Услуги</b></li>
            </ol>
            <p><span style={{fontWeight: '400'}}>6.1. Администрация оказывает Пользователю услуги по предоставлению информации касательно времени и места проведения волонтерских мероприятий, а также информацию о прочих Пользователях, принимающих участие в данных мероприятиях.&nbsp;&nbsp;</span></p>
            <p><br /><br /></p>
            <ol start="7">
            <li><b> Запрещенные действия</b></li>
            </ol>
            <p><span style={{fontWeight: '400'}}>Пользователь не вправе:</span></p>
            <p><span style={{fontWeight: '400'}}>7.1. Использовать Чат-бот, Библиотеки способами, прямо не предусмотренными Соглашением.</span></p>
            <p><span style={{fontWeight: '400'}}>7.2. Предпринимать попытки обойти технические ограничения, установленные Чат-бот.</span></p>
            <p><span style={{fontWeight: '400'}}>7.3. Использовать скрипты (программы) для автоматического сбора информации и\или взаимодействия с Чат-бот.</span></p>
            <p><span style={{fontWeight: '400'}}>7.4. Использовать Чат-бот для публикации, распространения, хранения, передачи в любой форме Контента, который:</span></p>
            <ol>
            <li><span style={{fontWeight: '400'}}>a) является незаконным, вредоносным, угрожающим, клеветническим, подстрекающим к насилию над каким-либо лицом или группой лиц, либо к бесчеловечному обращению с животными, призывает к совершению противоправной деятельности, в том числе разъясняет порядок применения взрывчатых веществ и иного оружия, нарушает общепринятые правила приличия и морально-этические нормы, пропагандирует ненависть и/или дискриминацию, а также содержит негативные и критические высказывания, касающиеся религии, политики, расовых, этнических, гендерных признаков, личных качеств, способностей, сексуальной ориентации и внешнего вида третьих лиц, содержит оскорбления в адрес конкретных лиц или организаций;</span></li>
            <li><span style={{fontWeight: '400'}}>b) может быть воспринят как пропаганда определенных политических и религиозных взглядов, нетрадиционной сексуальной ориентации, насилия, употребления наркотических средств, алкоголя и табакокурения;</span></li>
            <li><span style={{fontWeight: '400'}}>c) нарушает права несовершеннолетних лиц;</span></li>
            <li><span style={{fontWeight: '400'}}>d) нарушает права третьих лиц на результаты интеллектуальной деятельности и приравненные к ним средства индивидуализации, права на информацию, составляющую коммерческую тайну, наносящего вред чести, деловой репутации и достоинству третьих лиц, нарушающего национальное и международное законодательство;</span></li>
            <li><span style={{fontWeight: '400'}}>e) содержит не разрешенную к разглашению информацию;</span></li>
            <li><span style={{fontWeight: '400'}}>f) содержит вредоносное программное обеспечение (вирусы, черви, трояны или другие компьютерные коды, файлы или программы), предназначенное для нарушения, уничтожения либо ограничения функциональности любого компьютерного или телекоммуникационного оборудования (их частей), для осуществления несанкционированного доступа, для получения доступа к коммерческим программным продуктам, путем предоставления серийных номеров логинов, паролей, программ для их генерации и прочих средств для получения несанкционированного доступа к платным ресурсам, а также размещения ссылок на вышеуказанную информацию;</span></li>
            <li><span style={{fontWeight: '400'}}>g) представляет собой Спам, т.е. рассылку без согласия получателя сообщений коммерческого и некоммерческого характера в форме (включая, но не ограничиваясь): рекламы товаров, оборот которых запрещен или ограничен согласно законодательству РФ; сообщений социального и/или религиозно-мистического содержания с призывом о дальнейшем распространении таких сообщений (&laquo;писем счастья&raquo;); списков чужих адресов электронной почты; схем &laquo;пирамид&raquo;, многоуровневого (сетевого) маркетинга (MLM); реферальных ссылок; систем Интернет-заработка и онлайн-бизнесов;</span></li>
            <li><span style={{fontWeight: '400'}}>h) нарушает законодательство Российской Федерации, а также нормы международного права;</span></li>
            <li><span style={{fontWeight: '400'}}>i) может использоваться для незаконного сбора, хранения и обработки персональных данных других лиц.</span></li>
            </ol>
            <p><span style={{fontWeight: '400'}}>7.5. Использовать Чат-бот для:</span></p>
            <ol>
            <li><span style={{fontWeight: '400'}}>a) нарушения работы Чат-бот;</span></li>
            <li><span style={{fontWeight: '400'}}>b) размещения ссылок на ресурсы, содержание которых противоречит действующему законодательству РФ и нормам международного права;</span></li>
            <li><span style={{fontWeight: '400'}}>c) распространения не соответствующих действительности сведений относительно своей причастности к Администрации и /или ее партнерам;</span></li>
            <li><span style={{fontWeight: '400'}}>d) содействия любым мероприятиям, направленным на нарушение ограничений и запретов, налагаемых Соглашением, а также на нарушение норм действующего законодательства.</span></li>
            </ol>
            <p></p>
            <ol start="8">
            <li><b> Блокировка и удаление</b></li>
            </ol>
            <p><span style={{fontWeight: '400'}}>8.1. Администрация вправе незамедлительно осуществить блокировку Аккаунта в случае:</span></p>
            <ol>
            <li><span style={{fontWeight: '400'}}>a) выявления нарушения Пользователем положений Соглашения;</span></li>
            <li><span style={{fontWeight: '400'}}>b) выявления нарушения Пользователем положений действующего законодательства Российской Федерации;</span></li>
            <li><span style={{fontWeight: '400'}}>c) получения от третьих лиц претензии о нарушении Пользователем их прав;</span></li>
            <li><span style={{fontWeight: '400'}}>d) получения соответствующих требований от государственных органов.</span></li>
            </ol>
            <p><span style={{fontWeight: '400'}}>8.2. Пользователь обязуется самостоятельно предпринять все действия для прекращения нарушения.</span></p>
            <p><br /><br /></p>
            <ol start="9">
            <li><b> Тарифы</b></li>
            </ol>
            <p><span style={{fontWeight: '400'}}>9.1. Виды Тарифов доступны по ссылке ТУТ ССЫЛКА</span></p>
            <p><span style={{fontWeight: '400'}}>9.1.1. Все Тарифы указаны за использование Чат-бот и элементов Библиотеки с одного Аккаунта. В случае регистрации двух и более Аккаунтов, Тарифы уплачиваются каждым Пользователем (с каждого Аккаунта) самостоятельно.</span></p>
            <p><span style={{fontWeight: '400'}}>9.2. Тарифы могут изменяться по одностороннему решению Администрации. Стоимость оплаченного периода при этом изменению не подлежит.</span></p>
            <p><span style={{fontWeight: '400'}}>9.3. После регистрации Пользователь вправе использовать Платформу на бесплатном Тарифе. Администрация может предоставить Пользователю пробный период на платном Тарифе.</span></p>
            <p><span style={{fontWeight: '400'}}>9.4. Для перехода на платный Тариф на постоянной основе Пользователь оплачивает его способами, предложенными функционалом Чат-бот.&nbsp;</span></p>
            <p><span style={{fontWeight: '400'}}>9.5. Оплата Тарифа осуществляется на условиях cтопроцентной предоплаты.</span></p>
            <p><span style={{fontWeight: '400'}}>9.6. Датой оплаты является дата зачисления денежных средств на расчетный счет Администрации.</span></p>
            <p><span style={{fontWeight: '400'}}>9.7. Обязательства по оплате считаются исполненными в случае возврата денежных средств по требованию платежной организации.</span></p>
            <p><span style={{fontWeight: '400'}}>9.8. Неиспользование Пользователем услуг не освобождает Пользователя от их оплаты.</span></p>
            <p><span style={{fontWeight: '400'}}>9.9. По истечении срока действия Тарифа он автоматически продлевается на период, аналогичный предыдущему. Администрация осуществляет автоматическое списание соответствующей суммы с платежной карты Пользователя согласно действующим Тарифам.</span></p>
            <p><span style={{fontWeight: '400'}}>9.10. Пользователь вправе отменить функцию автоплатежа или уведомить Администрацию о нежелании продлевать платный Тариф не позднее чем за 3 (три) дня до окончания срока его действия.</span></p>
            <p><span style={{fontWeight: '400'}}>9.11. При первой оплате Тарифа Пользователь дает Администрации и её партнёрам согласие на хранение информации о его платежной карте и соглашается с тем, что Администрация уполномочена взимать плату с платежной карты для:</span></p>
            <ol>
            <li><span style={{fontWeight: '400'}}>a) реализации функции автоплатежа;</span></li>
            <li><span style={{fontWeight: '400'}}>b) списания иных платежей, связанных с Соглашением;</span></li>
            <li><span style={{fontWeight: '400'}}>c) погашения задолженности Пользователя по Соглашению.</span></li>
            </ol>
            <p><span style={{fontWeight: '400'}}>9.12. Условия оплаты банковской картой, банка партнера и другие условия оплаты размещены по адресу ТУТ АДРЕС.</span></p>
            <p><br /><br /><br /><br /></p>
            <ol start="10">
            <li><b> Права Администрации</b></li>
            </ol>
            <p><span style={{fontWeight: '400'}}>10.1. Изменять, дорабатывать и обновлять ЧАТ-бот без согласия и уведомления Пользователя.</span></p>
            <p><span style={{fontWeight: '400'}}>10.2. Устанавливать любые ограничения на использование Чат-бот.</span></p>
            <p><span style={{fontWeight: '400'}}>10.3. Удалять Аккаунт Пользователя, который к моменту удаления в течение четырех и более календарных месяцев:</span></p>
            <ol>
            <li><span style={{fontWeight: '400'}}>a) находится на бесплатном Тарифе;</span></li>
            <li><span style={{fontWeight: '400'}}>b) не используется Пользователем.</span></li>
            </ol>
            <p><span style={{fontWeight: '400'}}>10.4. Направлять Пользователю сообщения, уведомления, запросы, сведения рекламного и информационного характера.</span></p>
            <p><span style={{fontWeight: '400'}}>10.5. Направлять Пользователю информацию о вебинарах, видео и другую информацию о Чат-бот.</span></p>
            <p><span style={{fontWeight: '400'}}>10.6. Проводить профилактические работы, влекущие приостановление работы Платформы.</span></p>
            <p><span style={{fontWeight: '400'}}>10.7. В случае обращения Пользователя в техническую поддержку, выдавать доступ сотрудникам Администрации ко всей информации о Пользователе и третьих лицах, а также действиях совершенных указанными лицами в Боте, включая данные переписки (сообщения).</span></p>
            <p><span style={{fontWeight: '400'}}>10.8. В случае нарушения Пользователем условий настоящего Соглашения и/или правил (условий) использования Чат-бот, ограничить доступ Пользователя к Чат-бот без осуществления возврата уплаченных Пользователем денежных средств.</span></p>
            <p><span style={{fontWeight: '400'}}>10.9. По своему усмотрению и необходимости создавать, изменять и отменять правила Чат-бот без уведомлению Пользователя.</span></p>
            <p><span style={{fontWeight: '400'}}>10.10. Ограничивать доступ к любой информации Чат-бот.</span></p>
            <p></p>
            <ol start="11">
            <li><b> Персональные данные</b></li>
            </ol>
            <p><span style={{fontWeight: '400'}}>11.1. Пользователь выражает согласие на обработку персональных данных (адрес электронной почты, номер мобильного телефона , IP-адрес, геолокация, Контент) с момента акцепта настоящего Соглашения.</span></p>
            <p><span style={{fontWeight: '400'}}>11.3. Платформа осуществляет обработку персональных данных Пользователей в следующих целях:</span></p>
            <p><span style={{fontWeight: '400'}}>11.3.1. Предоставление Пользователям услуг, в частности регистрации в Чат-бот, исполнения условий пользовательского соглашения, услуг информационной и технической поддержки.</span></p>
            <p><span style={{fontWeight: '400'}}>11.3.2. Улучшение качества работы Чат-бот, удобства его использования для Пользователя, разработки новых услуг и сервисов.</span></p>
            <p><span style={{fontWeight: '400'}}>11.4. Действия по обработке персональных данных включают сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (предоставление, доступ), блокирование, удаление и уничтожение.</span></p>
            <p><span style={{fontWeight: '400'}}>11.7. Персональные данные Пользователя хранятся в течени 3-х лет после последней оплаты Тарифа Пользователем.</span></p>
            <p><span style={{fontWeight: '400'}}>11.8. В случае принятия решения Пользователем об осуществлении обработки, в том числе сбора, персональных данных третьих лиц с использованием вычислительных мощностей Платформы, Пользователь поручает Администрации осуществлять обработку персональных данных.</span></p>
            <p><span style={{fontWeight: '400'}}>Основанием для осуществления Администрацией обработки персональных данных субъектов персональных данных, загружаемых на Платформу Пользователем является настоящее Соглашение.</span></p>
            <p><span style={{fontWeight: '400'}}>Администрацией осуществляется автоматизированная обработка персональных данных с совершением следующих действий: сбор, запись, систематизация, накопление, хранение, уточнение (обновление, изменение), передача (предоставление, доступ), извлечение, использование, блокирование, удаление, уничтожение.</span></p>
            <p><span style={{fontWeight: '400'}}>11.9. При обработке персональных данных Администрация обязуется:</span></p>
            <ol>
            <li><span style={{fontWeight: '400'}}>a) обеспечивать конфиденциальность персональных данных. Администрация предоставляет доступ к персональным данным только тем работникам и третьим лицам, которым эта информация необходима для выполнения условий Пользовательского соглашения, обеспечивая соблюдение указанными лицами конфиденциальности персональных данных и безопасности персональных данных при их обработке;</span></li>
            <li><span style={{fontWeight: '400'}}>b) обеспечивать защиту персональных данных от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, предоставления, распространения персональных данных, а также от иных неправомерных действий в отношении персональных данных;</span></li>
            <li><span style={{fontWeight: '400'}}>c) назначить ответственного за организацию обработки персональных данных;</span></li>
            <li><span style={{fontWeight: '400'}}>d) установить правила доступа к персональным данным, а также обеспечить регистрацию и учёт всех действий с ними;</span></li>
            <li><span style={{fontWeight: '400'}}>e) производить оценку вреда, который может быть причинен в случае нарушения Федерального закона &laquo;О персональных данных&raquo; № 152-ФЗ от 27.07.06;</span></li>
            <li><span style={{fontWeight: '400'}}>f) производить определение угроз безопасности персональных данных;</span></li>
            <li><span style={{fontWeight: '400'}}>g) применять организационные и технические меры и использует средства защиты информации, необходимые для достижения установленного уровня защищенности персональных данных;</span></li>
            <li><span style={{fontWeight: '400'}}>h) осуществлять обнаружение фактов несанкционированного доступа к персональным данным и принимать меры по реагированию, включая восстановление персональных данных, модифицированных или уничтоженных вследствие несанкционированного доступа к ним;</span></li>
            <li><span style={{fontWeight: '400'}}>i) производить оценку эффективности принимаемых мер по обеспечению безопасности персональных данных;</span></li>
            <li><span style={{fontWeight: '400'}}>j) осуществлять внутренний контроль соответствия обработки персональных данных Федеральному закону &laquo;О персональных данных&raquo; № 152-ФЗ от 27.07.06;</span></li>
            <li><span style={{fontWeight: '400'}}>k) по требованиям Пользователя предоставлять документы и иную информацию, подтверждающую принятие мер и соблюдение требований Федерального закона &laquo;О персональных данных&raquo; № 152-ФЗ от 27.07.06;</span></li>
            <li><span style={{fontWeight: '400'}}>l) соблюдать требования по локализации персональных данных на территории Российской Федерации;</span></li>
            <li><span style={{fontWeight: '400'}}>m) незамедлительно в письменном виде сообщать Пользователю обо всех случаях неправомерной или случайно передачи (&laquo;утечки&raquo;) персональных данных.</span></li>
            </ol>
            <p><span style={{fontWeight: '400'}}>11.12. Администрация обуется не разглашать ставшую известной ей при оказании услуг в рамках настоящего Соглашения информацию, относящуюся в соответствии с действующим законодательством Российской Федерации к информации ограниченного доступа.</span></p>
            <p><br /><br /></p>
            <ol start="12">
            <li><b> Ответственность</b></li>
            </ol>
            <p><span style={{fontWeight: '400'}}>12.1. Администрация не несет ответственности за прямую или косвенную упущенную выгоду Пользователя.</span></p>
            <p><span style={{fontWeight: '400'}}>12.2. Ответственность Администрации по Соглашению не может превышать стоимости оплаченного Пользователем Тарифа.</span></p>
            <p><span style={{fontWeight: '400'}}>12.3. В случае предъявления Администрации претензий, требований и/или исков о нарушении прав третьих лиц, связанных с нарушением данных Пользователем гарантий, Пользователь принимает на себя обязательства по их урегулированию и возмещению ущерба Администрации в случае его возникновения, в том числе:</span></p>
            <ol>
            <li><span style={{fontWeight: '400'}}>a) уплачивает суммы любых штрафов, пеней, неустоек и т.п., взысканных (наложенных) на Администрацию по вине Пользователя. При этом Администрация не обязана обжаловать постановления (акты) контролирующих (и иных органов) о привлечении Администрации к ответственности и наложении штрафа;</span></li>
            <li><span style={{fontWeight: '400'}}>b) компенсирует все издержки Администрации (в том числе судебные расходы) связанные с необходимостью урегулировать предъявленные претензии, требования, иски.</span></li>
            </ol>
            <p><span style={{fontWeight: '400'}}>12.4. Администрация не несет ответственности за жизнь и здоровье участников на территории проведения волонтерских мероприятий.</span></p>
            <p></p>
            <ol start="13">
            <li><b> Срок Соглашения</b></li>
            </ol>
            <p><span style={{fontWeight: '400'}}>13.1. Соглашение действует с момент акцепта в неограниченном времени.</span></p>
            <p><br /><br /><br /><br /></p>
            <ol start="14">
            <li><b> Разрешение споров и разногласий</b></li>
            </ol>
            <p><span style={{fontWeight: '400'}}>14.1. Все споры или разногласия, возникающие между Сторонами в связи с исполнением Соглашения, разрешаются ими путем переговоров.</span></p>
            <p><span style={{fontWeight: '400'}}>14.2. При недостижении Сторонами соглашения, споры и разногласия подлежат разрешению в претензионном порядке. Срок ответа на претензию &mdash; 30 дней с момента её получения.</span></p>
            <p><br /><br /></p>
            <ol start="15">
            <li><b> Заключительные положения</b></li>
            </ol>
            <p><span style={{fontWeight: '400'}}>15.1. Соглашение может быть изменено или дополнено Администрацией без уведомления Пользователя в любое время. Новая редакция Соглашения вступает в силу с момента ее размещения на Чат-бот.</span></p>
            <p><span style={{fontWeight: '400'}}>15.2. Продолжение использования Чат-бот после внесения изменений и/или дополнений в Соглашение означает принятие и согласие Пользователя с такими изменениями и/или дополнениями.</span></p>
            <p><span style={{fontWeight: '400'}}>15.3. Соглашение регулируется и толкуется в соответствии с законодательством Российской Федерации. Вопросы, не урегулированные Соглашением, подлежат разрешению в соответствии с законодательством РФ.</span></p>
            <p><span style={{fontWeight: '400'}}>15.4. В случае, если какое-либо из положений Соглашения окажется ничтожным в соответствии с законодательством Российской Федерации, остальные положения останутся в силе, а Соглашение будет исполняться Сторонами в полном объеме без учета такого положения.</span></p>
            <p><br /><br /><br /></p>
        </div>
        </>
    );
}