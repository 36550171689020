// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.event {
    min-width: 122px;
    height: 147px;

    padding: 10px 5px 5px 5px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    border-radius: 15px;
    border: 1px solid #EFF1F7;
    background: #FFF;

    box-sizing: border-box;
    cursor: pointer;
}

.event--selected {
    border: 1px solid green;
}

.event__name {
    font-size: 14px;
    font-weight: 600;
    text-align: center;
}

.event__image {
    height: 70px;
}

.event__date {
    width: 100%;
    height: 29px;

    display: flex;
    align-items: center;
    justify-content: center;

    color: var(--primary-color);
    font-size: 12px;
    font-weight: 600;

    border-radius: 100px;
    background: #EFF1F7;
}

.event__user__amount {
    display: flex;
    align-items: center;
    gap:4px;
    margin-left: auto;
    margin-right: 4px;
    font-weight: 500;
}

.event__user__amount img {
    width: 16px;
    height: 16px;
}`, "",{"version":3,"sources":["webpack://./src/components/atoms/home/events/events.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,aAAa;;IAEb,yBAAyB;;IAEzB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,8BAA8B;;IAE9B,mBAAmB;IACnB,yBAAyB;IACzB,gBAAgB;;IAEhB,sBAAsB;IACtB,eAAe;AACnB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;;IAEZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;;IAEvB,2BAA2B;IAC3B,eAAe;IACf,gBAAgB;;IAEhB,oBAAoB;IACpB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,OAAO;IACP,iBAAiB;IACjB,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB","sourcesContent":[".event {\r\n    min-width: 122px;\r\n    height: 147px;\r\n\r\n    padding: 10px 5px 5px 5px;\r\n\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    justify-content: space-between;\r\n\r\n    border-radius: 15px;\r\n    border: 1px solid #EFF1F7;\r\n    background: #FFF;\r\n\r\n    box-sizing: border-box;\r\n    cursor: pointer;\r\n}\r\n\r\n.event--selected {\r\n    border: 1px solid green;\r\n}\r\n\r\n.event__name {\r\n    font-size: 14px;\r\n    font-weight: 600;\r\n    text-align: center;\r\n}\r\n\r\n.event__image {\r\n    height: 70px;\r\n}\r\n\r\n.event__date {\r\n    width: 100%;\r\n    height: 29px;\r\n\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n\r\n    color: var(--primary-color);\r\n    font-size: 12px;\r\n    font-weight: 600;\r\n\r\n    border-radius: 100px;\r\n    background: #EFF1F7;\r\n}\r\n\r\n.event__user__amount {\r\n    display: flex;\r\n    align-items: center;\r\n    gap:4px;\r\n    margin-left: auto;\r\n    margin-right: 4px;\r\n    font-weight: 500;\r\n}\r\n\r\n.event__user__amount img {\r\n    width: 16px;\r\n    height: 16px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
