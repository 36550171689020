// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
/* @media (max-width: 540px) {
    .input-item {
        margin-bottom: 20px;
    }
} */

.input-item__label {
    margin: 0 0 6px 19px;

    color: #696969;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

/* @media(max-width: 540px) {
    .input-item {
        margin: 10px 0;
    }
} */`, "",{"version":3,"sources":["webpack://./src/components/atoms/auth/inputItem/inputItem.css"],"names":[],"mappings":";AACA;;;;GAIG;;AAEH;IACI,oBAAoB;;IAEpB,cAAc;IACd,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;;;;GAIG","sourcesContent":["\r\n/* @media (max-width: 540px) {\r\n    .input-item {\r\n        margin-bottom: 20px;\r\n    }\r\n} */\r\n\r\n.input-item__label {\r\n    margin: 0 0 6px 19px;\r\n\r\n    color: #696969;\r\n    font-size: 14px;\r\n    font-style: normal;\r\n    font-weight: 600;\r\n    line-height: normal;\r\n}\r\n\r\n/* @media(max-width: 540px) {\r\n    .input-item {\r\n        margin: 10px 0;\r\n    }\r\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
