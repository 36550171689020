import React, { useEffect, useState } from 'react'
import person from '../../../../assets/images/person.png'
import './events.css'

export const Events = ({ events, selectedEvent, onEventSelect, sheltersTotalCount, chosedShelter }) => {
    console.log(events)

    const formatDate = (isoDateString) => {
        const date = new Date(isoDateString)

        const day = String(date.getDate()).padStart(2, '0')
        const month = String(date.getMonth() + 1).padStart(2, '0') // Месяцы в JavaScript начинаются с 0
        const year = date.getFullYear()
        const hours = String(date.getHours()).padStart(2, '0')
        const minutes = String(date.getMinutes()).padStart(2, '0')

        return `${day}.${month} • ${hours}:${minutes}`
    }

    useEffect(() => {
        if (events.length > 0) {
            onEventSelect(events[0])
        }
    }, [events])

    return (
        <>
            {events.map((event, i) => {
                console.log(sheltersTotalCount)
                const shelter = Array.isArray(sheltersTotalCount)
                    ? sheltersTotalCount.find((el) => el.id === event.id)
                    : null

                return (
                    <div key={i} className={`event ${selectedEvent?.id === event.id ? 'event--selected' : ''}`} onClick={() => onEventSelect(event)}>
                        <div className='event__name'>{event.name}</div>
                        <img
                            src={event.photo}
                            className='event__image'
                            alt='image-event'
                        />
                        <div className='event__user__amount'>
                            {shelter ? shelter.user_count : '0'}
                            <img src={person} alt='Users'/>
                        </div>
                        <div className='event__date'>
                            {formatDate(event.when)}
                        </div>
                    </div>
                )
            })}
        </>
    )
}
