// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
    width: 100%;
    height: 162px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background: linear-gradient(180deg, rgba(199, 129, 255, 0.17) 0%, rgba(217, 217, 217, 0.00) 100%);
}

.header__logo {
    display: flex;
    align-items: center;
}

.header__logo-title {
    margin-right: 15px;

    font-size: 54px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.header__logo-logo {
    width: 50px;
    height: 46px;
    border-radius: 7px;

    flex-shrink: 0;
}

.header__subtitle {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
}

@media (max-width: 540px) {
    .header {
        height: 122px;
    }
    .header__logo-title {
        font-size: 43px;
    }
    .header__logo-logo {
        width: 45px;
        height: 38px;
    }
    .header__subtitle {
        font-size: 10px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/common/header/header.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;;IAEb,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;;IAEvB,iGAAiG;AACrG;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;;IAElB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;;IAElB,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,yBAAyB;AAC7B;;AAEA;IACI;QACI,aAAa;IACjB;IACA;QACI,eAAe;IACnB;IACA;QACI,WAAW;QACX,YAAY;IAChB;IACA;QACI,eAAe;IACnB;AACJ","sourcesContent":[".header {\r\n    width: 100%;\r\n    height: 162px;\r\n\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    justify-content: center;\r\n\r\n    background: linear-gradient(180deg, rgba(199, 129, 255, 0.17) 0%, rgba(217, 217, 217, 0.00) 100%);\r\n}\r\n\r\n.header__logo {\r\n    display: flex;\r\n    align-items: center;\r\n}\r\n\r\n.header__logo-title {\r\n    margin-right: 15px;\r\n\r\n    font-size: 54px;\r\n    font-style: normal;\r\n    font-weight: 600;\r\n    line-height: normal;\r\n}\r\n\r\n.header__logo-logo {\r\n    width: 50px;\r\n    height: 46px;\r\n    border-radius: 7px;\r\n\r\n    flex-shrink: 0;\r\n}\r\n\r\n.header__subtitle {\r\n    font-size: 12px;\r\n    font-style: normal;\r\n    font-weight: 600;\r\n    line-height: normal;\r\n    text-transform: uppercase;\r\n}\r\n\r\n@media (max-width: 540px) {\r\n    .header {\r\n        height: 122px;\r\n    }\r\n    .header__logo-title {\r\n        font-size: 43px;\r\n    }\r\n    .header__logo-logo {\r\n        width: 45px;\r\n        height: 38px;\r\n    }\r\n    .header__subtitle {\r\n        font-size: 10px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
