// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#feedback__section {
    position: relative;
    margin-top: 90px;
    display: flex;
    flex-direction: column;
}

#feedback__section p {
    font-size: 48px;
    font-weight: 700;
    line-height: 57.6px;
    letter-spacing: -0.02em;
    text-align: left;
    margin-bottom: 53px;
}

.feedback__cards__container {
    position: relative;
    display: grid;
    grid-template-areas: "first first two";
    gap: 20px 20px;
}

.feedback__card:nth-child(1) {
    grid-area: first;
}

.feedback__card:nth-child(2) {
    grid-area: two;
}

@media (max-width:1024px) {
    #feedback__section p {
        font-size: 19px;
        line-height: 22.8px;
        margin-top: 32px;
    }

    #feedback__section p::after {
       content: " 💬";
    }

    .feedback__cards__container {
        grid-template-columns: 1fr;
        grid-template-areas: none;
    }

    .feedback__card:nth-child(1) {
        grid-area: initial;
    }

    .feedback__card:nth-child(2) {
        grid-area: initial;
    }
}`, "",{"version":3,"sources":["webpack://./src/screens/main/sections/feedback/feedbackSection.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,mBAAmB;IACnB,uBAAuB;IACvB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,sCAAsC;IACtC,cAAc;AAClB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI;QACI,eAAe;QACf,mBAAmB;QACnB,gBAAgB;IACpB;;IAEA;OACG,cAAc;IACjB;;IAEA;QACI,0BAA0B;QAC1B,yBAAyB;IAC7B;;IAEA;QACI,kBAAkB;IACtB;;IAEA;QACI,kBAAkB;IACtB;AACJ","sourcesContent":["#feedback__section {\r\n    position: relative;\r\n    margin-top: 90px;\r\n    display: flex;\r\n    flex-direction: column;\r\n}\r\n\r\n#feedback__section p {\r\n    font-size: 48px;\r\n    font-weight: 700;\r\n    line-height: 57.6px;\r\n    letter-spacing: -0.02em;\r\n    text-align: left;\r\n    margin-bottom: 53px;\r\n}\r\n\r\n.feedback__cards__container {\r\n    position: relative;\r\n    display: grid;\r\n    grid-template-areas: \"first first two\";\r\n    gap: 20px 20px;\r\n}\r\n\r\n.feedback__card:nth-child(1) {\r\n    grid-area: first;\r\n}\r\n\r\n.feedback__card:nth-child(2) {\r\n    grid-area: two;\r\n}\r\n\r\n@media (max-width:1024px) {\r\n    #feedback__section p {\r\n        font-size: 19px;\r\n        line-height: 22.8px;\r\n        margin-top: 32px;\r\n    }\r\n\r\n    #feedback__section p::after {\r\n       content: \" 💬\";\r\n    }\r\n\r\n    .feedback__cards__container {\r\n        grid-template-columns: 1fr;\r\n        grid-template-areas: none;\r\n    }\r\n\r\n    .feedback__card:nth-child(1) {\r\n        grid-area: initial;\r\n    }\r\n\r\n    .feedback__card:nth-child(2) {\r\n        grid-area: initial;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
